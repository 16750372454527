(function($) {
    function setBlockHeight(item_class){
        var grid_item = $(item_class);
        var block_height = 0;
        grid_item.each(function(){
            if (this.clientHeight > block_height) {
                block_height = this.clientHeight;
            }

        });
        grid_item.each(function(){
            $(this).css("height", block_height);
        });

    }
    $(window).on('resize', {extra: '.office-column'},function(e){
        var item_class = e.data.extra;
        var grid_item = $(item_class);
        grid_item.each( function(){$(this).css('height', 'auto');});
        setBlockHeight(item_class);
    });

    setBlockHeight('.office-column');

})(jQuery);
