/**
 * Flexslider Init (hero)
 */
jQuery(document).ready(function($){

    $('.testimonial-carosel .slides__outer').flexslider({
        selector: ".slides > .slide",
        animation: "slide",
        controlNav: true,
        customDirectionNav: $(".testimonial-carosel .flexslider-custom-navigation a"),
        animationLoop: true,
        slideshow: true,
        touch: true
    });

    $('.product-gallery .slides__outer').flexslider({
        selector: ".slides > .slide",
        animation: "slide",
        controlNav: true,
        customDirectionNav: $(".product-gallery .flexslider-custom-navigation a"),
        animationLoop: true,
        slideshow: true,
        touch: true
    });

    

    
});